
import React from 'react';

const AboutWedding: React.FC = () => {
  return (
    <section className="about-section">
      <h3>About the Wedding</h3>
      <p>Our wedding will be a celebration of love and togetherness. We look forward to creating unforgettable memories with you in one of the most beautiful places in the world.</p>
    </section>
  );
};

export default AboutWedding;
