
import React from 'react';

const Header: React.FC = () => {
  return (
    <header className="header" style={{ position: 'relative', textAlign: 'center', marginBottom: '20px' }}>
      <img 
        src="/images/cropped_middle_third_image.jpg" 
        alt="Wedding Header" 
        style={{ width: '100%', height: 'auto', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} 
      />
      <div className="overlay-text" style={{
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        color: '#fff', 
        fontWeight: 'bold', 
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        fontSize: '200%', /* Fixing font size to 200% */
        width: '100%',
      }}>
        Patrick & Dianne
      </div>
    </header>
  );
};

export default Header;
