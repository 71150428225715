
import React from 'react';
import { NavLink, NavLinkRenderProps } from 'react-router-dom';

const NavBar: React.FC = () => {
  const navLinkStyle = ({ isActive }: NavLinkRenderProps) => ({
    textDecoration: 'none',
    display: 'block',
    padding: '10px',
    textAlign: 'left' as 'left',
    backgroundColor: isActive ? '#444' : 'transparent',
    color: '#fff'
  });

  return (
    <nav className="nav-bar">
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li>
          <NavLink 
            to="/" 
            style={navLinkStyle}
          >
            <span role="img" aria-label="envelope">💌</span> RSVP
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/about-couple" 
            style={navLinkStyle}
          >
            <span role="img" aria-label="couple">💑</span> Couple
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/about-boracay" 
            style={navLinkStyle}
          >
            <span role="img" aria-label="palm tree">🌴</span> Boracay
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/about-wedding" 
            style={navLinkStyle}
          >
            <span role="img" aria-label="wedding">💍</span> Wedding
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
