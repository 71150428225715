
import React from 'react';

const AboutBoracay: React.FC = () => {
  return (
    <section className="about-section">
      <h3>Boracay Island</h3>
      <p>
        Boracay Island is a tropical paradise known for its crystal-clear waters, powdery white sand beaches, and vibrant island culture. 
        It has been a dream destination for many travelers and is the perfect backdrop for our wedding celebration.
      </p>
      <div className="video-container" style={{ marginTop: '20px', textAlign: 'center' }}>
        <iframe 
          style={{width: 560, height: 315, maxWidth: '100%'}}
          src="https://www.youtube.com/embed/SuvJab2i3hU" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          allowFullScreen>
        </iframe>
      </div>
      <ul>
        <li>
          <strong>Central Boracay (Stations 1, 2, and 3)</strong> offers a lively and walkable environment, perfect for tourists exploring the area.
          <ul>
            <li><strong>Beachfront beauty:</strong> On one side, you'll find the iconic white sand beach, ideal for relaxing walks and soaking in the tropical atmosphere.</li>
            <li><strong>Bustling nightlife and dining:</strong> The opposite side is lined with a wide variety of restaurants, bars, and clubs, adding a vibrant energy to the scene.</li>
          </ul>
        </li>
        <li><strong>Transportation:</strong> Tricycles are the primary mode of transport, offering convenient, budget-friendly rides around the island. Private cars are seldom seen, making the area very pedestrian-friendly.</li>
        <li><strong>Best time to visit:</strong> The hottest months are March, April, and May, when temperatures soar above 30°C. During this period, rain is very rare, ensuring abundant sunshine for beach lovers and outdoor adventurers.</li>
        <li><strong>Exciting activities:</strong> Central Boracay is packed with thrilling activities such as parasailing, deep-sea diving, snorkeling, zip lining, ATV driving, and island hopping, catering to both adrenaline seekers and relaxation enthusiasts alike.</li>
      </ul>
    </section>
  );
};

export default AboutBoracay;
