
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import Header from './components/Header';
import AboutCouple from './components/AboutCouple';
import AboutBoracay from './components/AboutBoracay';
import AboutWedding from './components/AboutWedding';
import RSVP from './components/RSVP';

const App: React.FC = () => {
  return (
    <Router>
      <div className="main-content">
        <NavBar />
        <Header />
        <Routes>
          <Route path="/" element={<RSVP />} />
          <Route path="/about-couple" element={<AboutCouple />} />
          <Route path="/about-boracay" element={<AboutBoracay />} />
          <Route path="/about-wedding" element={<AboutWedding />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
