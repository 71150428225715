
import React, { useState, useEffect } from 'react';

const images = [
  '/images/DSC00629.jpg',
  '/images/DSC01536.jpg',
  '/images/DSC09688.jpg'
];

const calculateTimeLeft = () => {
  const weddingDate = new Date('May 25, 2025 16:00:00 GMT+0800').getTime();
  const now = new Date().getTime();
  const timeLeft = weddingDate - now;

  return {
    months: Math.floor(timeLeft / (1000 * 60 * 60 * 24 * 30)),
    days: Math.floor((timeLeft % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)),
    hours: Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((timeLeft % (1000 * 60)) / 1000)
  };
};

const RSVP: React.FC = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage === images.length - 1 ? 0 : prevImage + 1));
    }, 3000); // Change image every 3 seconds

    const countdownInterval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000); // Update countdown every second

    return () => {
      clearInterval(interval);
      clearInterval(countdownInterval);
    };
  }, []);

  return (
    <section className="rsvp-section">
      <h3>RSVP</h3>
      <p>We'd love to know if you can join us. Please fill out the form below to let us know!</p>

      <div className="countdown">
        <h4>Countdown to Our Wedding:</h4>
        <div className="countdown-timer" style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          flexWrap: 'nowrap',
          gap: '10px',
          fontSize: '1.4em', // Slightly smaller default font size for larger screens
          overflowX: 'auto'
        }}>
          <div className="countdown-item">
            <span>{timeLeft.months}</span> Months
          </div>
          <div className="countdown-item">
            <span>{timeLeft.days}</span> Days
          </div>
          <div className="countdown-item">
            <span>{timeLeft.hours}</span> Hours
          </div>
          <div className="countdown-item">
            <span>{timeLeft.minutes}</span> Minutes
          </div>
          <div className="countdown-item">
            <span>{timeLeft.seconds}</span> Seconds
          </div>
        </div>
      </div>

      <style>
        {`
          @media (max-width: 768px) {
            .countdown-timer {
              font-size: 1.1em; /* Shrink font size for medium screens */
            }
          }

          @media (max-width: 480px) {
            .countdown-timer {
              font-size: 0.9em; /* Shrink font size for smaller screens */
            }
          }
        `}
      </style>

      <div className="rsvp-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        <div className="carousel" style={{ flex: '1' }}>
          <img
            src={images[currentImage]}
            alt="Wedding Moment"
            style={{ width: '100%', height: 'auto', maxWidth: '500px', borderRadius: '12px' }}
          />
        </div>

        <div className="rsvp-form" style={{ flex: '1', marginLeft: '50px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <form style={{ width: '80%' }}>
            <label style={{ display: 'block', marginBottom: '10px' }}>
              Name:
              <input type="text" name="name" style={{ marginLeft: '10px', padding: '5px', width: '100%' }} />
            </label>
            <label style={{ display: 'block', marginBottom: '10px' }}>
              Email:
              <input type="email" name="email" style={{ marginLeft: '10px', padding: '5px', width: '100%' }} />
            </label>
            <label style={{ display: 'block', marginBottom: '10px' }}>
              Will you attend?
              <select name="attending" style={{ marginLeft: '10px', padding: '5px', width: '100%' }}>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </label>
            <button type="submit" style={{ padding: '10px 20px', backgroundColor: '#444', color: '#fff', border: 'none', cursor: 'pointer' }}>
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default RSVP;
