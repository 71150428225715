
import React from 'react';

const AboutCouple: React.FC = () => {
  return (
    <section className="about-section">
      <h3>About the Couple</h3>
      <div style={{fontFamily: 'Arial, sans-serif', lineHeight: '1.6'}}>
        <p>
            <strong>In a world brought together by technology and serendipity, Patrick and Dianne’s love story began like many modern romances—a match on a dating app. But what unfolded after that fateful swipe was anything but ordinary.</strong>
        </p>

        <p>
            Patrick, a Filipino American software engineer, was on a personal journey of rediscovery, traveling the vibrant landscapes of the Philippines in search of deeper connections with his heritage. Dianne, with her sharp wit and warm heart, captivated him instantly, their first conversations like sparks igniting a flame that would only grow stronger. They began their adventures in Luzon, exploring its hidden gems, before venturing together to the paradisiacal shores of the Visayas, where they first fell in love with Boracay—a place that would one day witness their vows.
        </p>

        <p>
            <em>Distance was no match for their devotion.</em> Across twelve time zones and thousands of miles, they built a love fortified by nightly phone calls and long video chats. Patrick, coding his way through late nights in New York, and Dianne, crunching numbers as an accountant for a corporate bank, always found time to connect. Each conversation was a reminder that despite their careers, cultures, and continents, they had discovered something rare—an unwavering commitment to each other.
        </p>

        <p>
            As they traveled together, exploring the vibrant street markets of Southeast Asia, the bustling cities of East Asia, and the historic cobblestones of Europe, their love evolved. Patrick, once a fan of strong, bitter coffee, found himself savoring sweet pastries and café treats at Dianne’s suggestion. Meanwhile, Dianne embraced the bold, spicy flavors Patrick loved, expanding her palate with every new dish they shared. Together, they developed a mutual love for diverse sauces, each flavor symbolizing the richness and complexity of their relationship.
        </p>

        <p>
            In March 2023, after years of laughter, discovery, and quiet moments shared across oceans, Patrick knew. During Dianne's first visit to his home in New York, he felt it in his bones—she was his home, too. With his heart set on a future together, he flew back to the Philippines, secretly arranging a proposal that would take her breath away. On a night illuminated by the glow of the Makati skyline, surrounded by both of their families, Patrick dropped to one knee, a ring in his hand and love in his eyes. With a tearful “yes,” their journey took a new, exhilarating turn.
        </p>

        <p>
            A year later, on leap day 2024, Patrick and Dianne stood hand in hand in a New York City hall, exchanging vows in an intimate ceremony. It was the beginning of a new chapter—one that would carry them back to Boracay for the wedding of their dreams, and onward to a future filled with endless adventures, flavors, and memories yet to be made.
        </p>
    </div>
    </section>
  );
};

export default AboutCouple;
